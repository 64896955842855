// Initialize first
@import '../init';

// Add your style here
.faq {
  // Hero
  .hero {
    .container {
      padding-top: toRem(166);
      padding-bottom: toRem(80);
      display: flex;
      flex-direction: column;
      gap: toRem(64);
      align-items: center;

      @include media-breakpoint-down(md) {
        padding: toRem(64) 0;
        padding-top: toRem(136);
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
        gap: toRem(48);
      }
    }

    &__header {
      max-width: 460px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: toRem(24);

      h1 {
        color: $color-white;
      }

      p {
        color: $color-lighter-grey;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: toRem(48);

      .content-card {
        display: flex;
        flex-direction: column;
        gap: toRem(32);

        .card-wrap {
          padding: toRem(48) toRem(64);
          background-color: $color-white;
          box-shadow: $shadow-md;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: toRem(32);

          @include media-breakpoint-down(sm) {
            padding: toRem(28) toRem(20);
          }

          .card-text {
            display: flex;
            flex-direction: column;
            gap: toRem(24);

            h4 {
              color: $color-black;
            }

            p {
              color: $color-grey;
              @include text-shorten($line: 2);
            }
          }
        }
      }

      .content-more {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: toRem(24);
        align-items: center;
        max-width: 367px;

        .link--default {
          display: unset;
        }

        h4 {
          color: $color-black;
        }
      }
    }
  }
}
